import { createCustomEqual, deepEqual } from 'fast-equals'
import { useEffect, useRef } from 'react'
import type { EffectCallback } from 'react'
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
export function useDeepCompareEffectForMaps(
    callback: EffectCallback,
    dependencies: any[]
) {
    useEffect(callback, dependencies.map(useDeepCompareMemorize))
}

function useDeepCompareMemorize(value: any) {
    const ref = useRef()

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}




// @ts-ignore
const deepCompareEqualsForMaps = createCustomEqual((deepEqual: any, ref: any) => (a: any, b: any) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof window.google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof window.google.maps.LatLng
    ) {
        return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    return deepEqual(a, b);
}) ;