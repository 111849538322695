const style = [
    {
        elementType: "geometry",
        stylers: [
            {
                width: '100%',
                height: '100%',
                borderRadius: 0
            },
        ],
    },

];

export default style;