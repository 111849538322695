import React, {useMemo} from "react";
import MapOverlayView from "./MapOverlayView";
import {UserWithCoordinate} from "../../types";
import {Box} from "@mui/material";

interface CustomMapMarkerProps {
    user: UserWithCoordinate;
    map?: google.maps.Map;
    onClick: (payload: UserWithCoordinate) => void;
    highlight?: boolean;
}

const CustomMapMarker = (props:CustomMapMarkerProps) => {
const {user,map} = props

    return (
        <>
            {map  ? (
                <MapOverlayView
                    position={{
                        lat: user.coordinate_lat,
                        lng: user.coordinate_lng,
                    }}
                    map={map}

                >
                    <Box sx={{
                        width: '30px',
                        height: '30px',
                        cursor: 'pointer',
                    }}
                    >
                        <img  style={{
                            width:'90%',
                            height:'90%',
                            objectFit:'contain',
                        }}  src={user.role.title == 'Senior'  ?  'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Map_pin_icon_green.svg/752px-Map_pin_icon_green.svg.png' :  '/images/helfer_icon_map.png'} />
                    </Box>
                </MapOverlayView>
            ) : null}
        </>

    )
}

export default CustomMapMarker