import React, { useEffect, useState } from 'react';
import { ErrorHandler } from 'components';
import styles from './Application.module.css';
import { useTranslation } from "react-i18next";
import Navbar from "./components/Navbar";
import { Header } from "./components/Header";
import { UserContext, UserContextType } from "views/auth/context/UserContext";
import config from "config";
import io from 'socket.io-client';
import { LayoutContext } from 'contexts/LayoutContext';
import { SocketContextProvider } from 'contexts/socket';

const Application = (props: any & { navType: 'collapsed' | 'expanded' }) => {
    const { children } = props;
    const { auth } = React.useContext(UserContext) as UserContextType
    const layoutContext = React.useContext(LayoutContext);

    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const { t } = useTranslation();

    const handleOpenMenu = () => {
        setMobileNavOpen(true);
    }
    const handleCloseMenu = () => {
        setMobileNavOpen(false);
    }

    useEffect(() => {

    }, []);

    return (
        <SocketContextProvider>
            <div className={styles.root}>
                <div className={styles.layout}>
                    <div className={styles.wrapper}>
                        <Navbar open={isMobileNavOpen} onClose={handleCloseMenu} isCollapsed={(layoutContext?.navbarType ?? '') === 'collapsed'} />
                        <div className={styles.content}>
                            <Header handleOpenMenu={handleOpenMenu} />
                            <ErrorHandler>{children}</ErrorHandler>
                        </div>
                    </div>
                </div>
            </div>
        </SocketContextProvider>
    );
};

export default Application;
