import React, { useState } from "react";

interface View {
    id: string
    width: number
}

export interface LayoutContextType {
    views: View[]
    navbarType: INavbarType
    openView: (view: View) => void
    closeViews: (ids: string[]) => void
    setNavbarType: (type: INavbarType) => void
}

export interface ILayoutContextValues {
    views?: View[]
    navbarType?: INavbarType
}

export const LayoutContext = React.createContext<LayoutContextType | null>(null);

interface LayoutContextProviderProps {
    children: React.ReactNode
}

export type INavbarType = "collapsed" | "expanded" | undefined

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {

    const [views, setViews] = useState<View[]>([]);
    const [navbarType, setNavType] = useState<INavbarType>();

    const openView = (view: View) => {
        setViews(views => [...views, view]);
    }

    const closeViews = (ids: string[]) => {
        setViews(views => views.filter(view => !ids.includes(view.id)));
    }

    const setNavbarType = (type: INavbarType)=>{
        setNavType(type);
    }

    return <LayoutContext.Provider value={{
        views,
        closeViews,
        openView,
        navbarType,
        setNavbarType
    }}>
        {children}
    </LayoutContext.Provider>

}


