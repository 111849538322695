// @flow
import * as React from 'react';


const Logout = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 1C14.995 1 16 2.005 16 3.25V13.75C16 14.995 14.995 16 13.75 16" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.75 4.75L1 8.5L4.75 12.25" stroke="#6C6965" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.75 8.5H1" stroke="#6C6965" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Logout