import * as React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Routes from "./routes";
import Auth from "./routes/Auth";
import { ProgressBarStyle } from "./components/ProgressBar";
import { ScrollToTop } from "./components";
import SnackbarCloseButton from "./components/SnackbarCloseButton";
// Locales
import 'translations';
import 'moment/locale/de';
import 'moment/locale/fr';
// contexts
import ThemeProvider from "./theme";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from 'notistack'
import { UserContextProvider } from "./views/auth/context/UserContext";
import { LayoutContextProvider } from 'contexts/LayoutContext';

export default function App() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retryDelay: 10 * 1000,
                retry: process.env.NODE_ENV !== 'development',
            },
            mutations: {
                retry: false
            }
        }
    });

    return (
        <QueryClientProvider
            client={queryClient}>
            <UserContextProvider>
                <SnackbarProvider
                    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}>
                    <ThemeProvider>
                        <LayoutContextProvider >
                            <React.Fragment>
                                <CssBaseline />
                                <ProgressBarStyle />
                                <ScrollToTop />
                                <Auth>
                                    <Routes />
                                </Auth>
                            </React.Fragment>
                        </LayoutContextProvider>
                    </ThemeProvider>
                </SnackbarProvider>
            </UserContextProvider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}
