import React, {ReactElement, useMemo} from 'react';
import {Circle, mapEventHandler,} from 'google-maps-react';
import {FormikPlaceLocation} from "../../form/FormikSelectLocation";
import SecureLS from "secure-ls";
import {UserWithCoordinate} from 'types';
import {Status, Wrapper} from '@googlemaps/react-wrapper';
import {Box, LinearProgress} from "@mui/material";
import config from "../../config";
import CustomMapMarker from "./MapMarker";
import Map from "../Map";
import {UserRequest} from "../../views/users/logic/user_types";
import MapCircle from "./MapCircle";


const mapStyles = {
    width: '100%',
    height: '100%',
    borderRadius: 0
};

const ls = new SecureLS({encodingType: 'aes'});


const render = (status: Status): ReactElement => {
    if (status === Status.FAILURE) return <Box> une erreur est survenue </Box>
    return <LinearProgress/>
}
type Props = {
    onIdle?: (map: google.maps.Map) => void;
    onClick?: (e: google.maps.MapMouseEvent) => void;
    onMarkerClick: (payload: UserWithCoordinate) => void;
    markers?: UserWithCoordinate[];
    center: google.maps.LatLngLiteral;
    zoom: number;
    highlightedMarkerId?: string;
    add_circle:boolean,
    request_detail?: UserRequest | undefined,
    circle_coordinate?:google.maps.LatLngLiteral,

}
const MapContainer = (props: Props) => {
    const {  onClick,
        onIdle,
        zoom,
        center,
        markers,
        onMarkerClick,
        highlightedMarkerId,  request_detail} = props
    const filtered = useMemo(() => {
        return markers?.filter((m) => m.coordinate_lng && m.coordinate_lat);
    }, [markers]);
    return(
        <Wrapper apiKey={config.map_key ?? ''} render={render}>
            <Map
                className="grow h-full"
                center={center}
                zoom={zoom}
                minZoom={2}
                maxZoom={22}
                onIdle={onIdle}
                onClick={onClick}
                fullscreenControl={false}
                streetViewControl={false}
                mapTypeControl={false}
                zoomControl={false}
                clickableIcons={false}
            >
                {filtered?.map((user) => (
                    <CustomMapMarker
                        key={user.public_id}
                        user={user}
                        onClick={()=> onMarkerClick(user) }
                        highlight={user.public_id === highlightedMarkerId}
                    />
                ))}
                {
                    props.add_circle ?   <MapCircle
                        key={request_detail?.id}
                        radius={zoom >= 16 ? 10 : 10 * Math.pow(2, 16 - zoom)}
                        request_detail={request_detail}
                    /> : null
                }
            </Map>
        </Wrapper>
    )
}
export default MapContainer