// @flow
import * as React from 'react';


const NavigationStatistics = () => {
    return (
        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 1H1V15H21V1Z" stroke="#6C6965" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12V5" stroke="#6C6965" strokeLinejoin="round"/>
            <path d="M8 12V9" stroke="#6C6965" strokeLinejoin="round"/>
            <path d="M11 12V6" stroke="#6C6965" strokeLinejoin="round"/>
            <path d="M14 12V8" stroke="#6C6965" strokeLinejoin="round"/>
            <path d="M17 12V4" stroke="#6C6965" strokeLinejoin="round"/>
        </svg>

    );
};

export default NavigationStatistics