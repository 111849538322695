// @flow
import * as React from 'react';


const NavigationDienstle = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.31 21H5.69C4.71 21 3.88 20.29 3.72 19.33L2 9H20L18.28 19.33C18.12 20.29 17.28 21 16.31 21Z" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 9H21" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 13H16" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 17H15" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 9H10V1.5C10 1.22 10.22 1 10.5 1H11.5C11.78 1 12 1.22 12 1.5V9Z" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 24.9987V22.332" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M18 15.6667V13" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M14.6667 19H12" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M24.0026 19H21.3359" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M22.2394 23.2394L20.3594 21.3594" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.6378 16.6417L13.7578 14.7617" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.6378 21.3594L13.7578 23.2394" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M22.2394 14.7617L20.3594 16.6417" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M17.9974 22.3346C19.8383 22.3346 21.3307 20.8422 21.3307 19.0013C21.3307 17.1604 19.8383 15.668 17.9974 15.668C16.1565 15.668 14.6641 17.1604 14.6641 19.0013C14.6641 20.8422 16.1565 22.3346 17.9974 22.3346Z" fill="white" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default NavigationDienstle