import {UserWithCoordinate} from "../../types";
import CircleMapOverview from "./CircleMapOverview";
import {UserRequest} from "../../views/users/logic/user_types";
import React from "react";

type MapCircleProps = {
    user?: UserWithCoordinate;
    map?: google.maps.Map;
    request_detail?: UserRequest | undefined,
    radius:number

}
const MapCircle = ({map,request_detail,radius}:MapCircleProps) =>{

    return(
        <>
            {
                map ?  <CircleMapOverview
                    key={'map-circle'}
                    map={map}
                    position={{
                        lat: request_detail?.coordinate[0] ?? 48.783333,
                        lng:request_detail?.coordinate[1] ?? 9.183333,
                    }}
                    radius={radius}

                /> : null
            }

        </>
    )
}

export  default  MapCircle