// @flow
import * as React from 'react';
import { useRef, useState } from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    ButtonBase,
    Container,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext, UserContextType } from "views/auth/context/UserContext";
import getInitials from "utils/getInitials";
import SecureLS from "secure-ls";
import Notification from "./Notification";
import AddOrUpdateUser from "../../../components/AddOrUpdateUser/AddOrUpdateUser";
import {SearchIconRounded} from 'assets/search'

const ls = new SecureLS({ encodingType: 'aes' });

type HeaderProps = {
    handleOpenMenu: () => void
};
export const Header = (props: HeaderProps) => {
    const isDesktop = useResponsive('up', 'lg');
    const isMobile = useResponsive('down', 'sm');
    const { enqueueSnackbar } = useSnackbar();
    const { logout, auth } = React.useContext(UserContext) as UserContextType
    const { i18n } = useTranslation();
    const buttonLanguage = useRef<HTMLButtonElement>(null);
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const menu = ["profile_user", "password_user"] as const

    const handleCloseUserMenu = (value?: string) => {
        let href = "";
        if (value === "profile_user") {
            href = "/app/profile";
        } else if (value === 'password_user') {
            href = "/app/password";
        }
        if (location.pathname === href) {
            window.location.reload();
        } else if (href) {
            history.push(href);
        }
        setAnchorElUser(null);
    };

    const handleClose = (lng: string | undefined) => {
        if (!!lng) {
            i18n.changeLanguage(lng.toLowerCase());
            ls.set('language', lng.toLowerCase());
        }
        setLanguageMenuOpen(false)
    };

    const [addUser, setAddUser] = useState(false);

    return (
        <AppBar
            sx={{
                width: '100%',
                backgroundColor: '#fff',
            }}
            elevation={0}
            color={'transparent'}
            position="static"
        >
            {
                addUser &&
                <AddOrUpdateUser
                    open={addUser}
                    onClose={() => setAddUser(false)}
                />
            }
            <Menu
                id="simple-menu"
                anchorEl={buttonLanguage.current}
                keepMounted
                open={languageMenuOpen}
                onClose={() => setLanguageMenuOpen(false)}>
                {[
                    { value: 'de', title: 'Deutch' },
                    { value: 'fr', title: 'Français' },
                    { value: 'en', title: 'English' }
                ].map((i) => (
                    <MenuItem onClick={() => handleClose(i.value)} key={i.value}>
                        {i.title}
                    </MenuItem>
                ))}
            </Menu>
            <Menu
                sx={{ mt: "42px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => handleCloseUserMenu()}
            >
                {menu.map((item) => (
                    <MenuItem
                        key={item}
                        onClick={() => {
                            handleCloseUserMenu(item);
                        }}
                    >
                        {t(item)}
                    </MenuItem>
                ))}
            </Menu>
            <Toolbar
                className={"lorem"}
                sx={{
                    px: "0px !important"
                }}
            >
                <Container
                    maxWidth={'xl'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {!isDesktop && <Box width={64}>
                            <IconButton
                                size="large"
                                edge="start"
                                onClick={props.handleOpenMenu}
                                color="inherit"
                                aria-label="menu"
                            >
                                <img src="/icons/menu.svg" width={28} alt="" />
                            </IconButton>
                        </Box>}
                        <Box
                            component="div"
                            sx={{
                                flexGrow: 1, display: 'flex', justifyContent: 'center',
                            }}
                        >
                            {/* {
                                !isMobile ? <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    gap={"13px"}
                                    width={"100%"}
                                >
                                    <TextField
                                        variant={'outlined'}
                                        fullWidth
                                        placeholder={t('global_search')}
                                        InputProps={{
                                            endAdornment: <InputAdornment position={'end'}>
                                                <img src="/icons/search.svg" alt="" />
                                            </InputAdornment>
                                        }}
                                    />
                                    <Button
                                        color={"primary"}
                                        startIcon={<img src="/icons/add.svg" alt="" />}
                                        onClick={() => setAddUser(true)}
                                    >
                                        {t('btn_label_add_user')}
                                    </Button>
                                </Stack>
                                    : <>
                                        <IconButton sx={{ ml: 'auto' }}>
                                            <SearchIconRounded/>
                                        </IconButton>
                                    </>} */}
                        </Box>
                        <IconButton sx={{ ml: isDesktop ? '48px' :0 }}>
                            <img src="/icons/message.svg" alt="" />
                        </IconButton>
                        <Notification />
                        <Box sx={{
                            height: '36px',
                            width: '1px',
                            mr: isDesktop ? '42px' : '24px',
                            ml: 1,
                            bgcolor: '#9F9996'
                        }} />
                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                            {isDesktop && <Typography variant={'subtitle1'} sx={{ mr: '12px' }}>
                                {auth?.user?.firstname} {auth?.user?.lastname}
                            </Typography>}
                            <ButtonBase sx={{ borderRadius: '100%' }} onClick={(e) => {
                                setAnchorElUser(e.currentTarget);
                            }}>
                                <Avatar src={auth?.user?.avatar?.url}>
                                    {getInitials(`${auth?.user?.firstname} ${auth?.user?.lastname}`)}
                                </Avatar>
                            </ButtonBase>
                        </Box>
                    </Box>

                </Container>
            </Toolbar>
        </AppBar>
    );
};