import {PropsWithChildren, useEffect, useMemo} from "react";
import {createMapOverlay} from "./MapOverlay";
import {createPortal} from "react-dom";

type CircleMapOverviewProps = PropsWithChildren<{
    position: google.maps.LatLng | google.maps.LatLngLiteral,
    pane?: keyof google.maps.MapPanes,
    zIndex?: number,
    strokeColor?: string,
    radius?: number,
    map: google.maps.Map
}>


const CircleMapOverview = ({
                               map,
                               zIndex,
                               position,
                               pane = 'floatPane',
                               radius,
                               children
                           }: CircleMapOverviewProps) => {
    const kilometerToPixelUnit = 3779528.0352161
    const container = useMemo(() => {
         let dimension = (radius ?? 1 * 1.60934)
        // let dimension = 1000
        const div = document.createElement('div')
        div.style.width = dimension + 'px'
        div.style.height = dimension + 'px'
        div.style.borderRadius = '50%'
        div.style.background = 'radial-gradient(50% 50% at 50% 50%, rgba(255, 98, 98, 0) 0%, rgba(255, 38, 38, 0.5) 100%)'
        div.style.position = 'absolute'
        return div
    }, [])
    console.log({container})
    console.log({map})
    const overlay = useMemo(() => {
        return createMapOverlay(container, pane, position)
    }, [container, pane, position])


    useEffect(() => {
        overlay?.setMap(map)
        return () => overlay?.setMap(null)
    }, [map, overlay])


    useEffect(() => {
        container.style.zIndex = `${zIndex}`
    }, [zIndex, container])

    return createPortal(children, container)
}

export default CircleMapOverview