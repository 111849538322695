import { Pagination } from 'types';
import BaseService from './BaseService';
import { matchMakerUrls, RequestUrls } from './urls';

class RequestService {
    static get_request_details = (uuid: string) => {
        return BaseService.getRequest(RequestUrls.GET_REQUEST_DETAIL(uuid), true)
    }

    static get_request_details_for_user = (uuid: string, user_public_id: string) => {
        return BaseService.getRequest(RequestUrls.GET_REQUEST_DETAIL_USER(uuid, user_public_id), true)
    }

    static get_all_requests = (range: Pagination) => {
        return BaseService.getRequest(RequestUrls.GET_REQUEST_LIST(range), true)
    }

    static export_all_requests = (start_date: string, end_date: string, file_type: string) => {
        return BaseService.getRequest(RequestUrls.EXPORT_REQUEST(start_date, end_date, file_type), true)
    }

    static getHelfers = (request_uuid: string) => {
        return BaseService.getRequest(matchMakerUrls.GET_HELFERS(request_uuid), true);
    }

    static checkMatch = (payload: { uuid: string, helfer_public_id: string }) => {
        return BaseService.postRequest(matchMakerUrls.CHECK_MATCH(payload), {}, true);
    }

    static getTimeLineInfo = (request_uuid: string)=>{
        return BaseService.getRequest(RequestUrls.TIME_LINE_INFO(request_uuid), true)
    }
}

export default RequestService;
