// export const prefixer = process.env.REACT_API_URL;
import {Pagination} from "types";
import config from "../../config";
import {ChartUserDatePeriod, StatsUserCategory} from "../../views/home/logic/home_hooks";
import moment from "moment-timezone";
import {TaskStatisticPagination, UserStatisticPagination} from "../../views/home/logic/home_types";
import { StatsUserType } from "views/users/logic/user_hooks";


export const prefixer = config.api_url;

export interface GlobalPaginate {
    page?: number;
    per_page?: number;
    keyword?: string;
}


export const AuthUrls = {
    UPDATE_USER_INFO: `${prefixer}auth/update`,
    UPDATE_PASSWORD: `${prefixer}auth/update-password`,
    LOGIN_USER: `${prefixer}authentification/login`,
    CONFORM_LOGIN_USER_OTP: `${prefixer}auth/opt/login-validate`,
    GET_CONNECTED_INFO: `${prefixer}auth/me`,
    SEND_MAIL_RESET_PASSWORD: (email: string) => `${prefixer}authentification/password-recovery/${email}`,
    VALIDATE_NEW_PASSWORD: `${prefixer}authentification/reset-password/`,
};

export const UsersStatsUrls = {
    GET_TOTAL: (endpoint: string, year: string) => `${prefixer}statistics/${endpoint}?filter=${year}`,
    GET_CHART_DATA: (start_date: string, end_date: string, filter: ChartUserDatePeriod) => `${prefixer}statistics/orders-analytics?start_date=${start_date}&end_date=${end_date}&filter=${filter}`,
}

export const GlobalUrl = {
    GET_GLOBAL_DATA: `${prefixer}users/default/data`,
};

export const UserUrls = {

    LIST_STATISTICS_USERS: (p: UserStatisticPagination) => `${prefixer}statistics/users-list?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword || ''}&order=${p.order || 'desc'}&order_field=${p.order_field || 'date_added'}${p.role !== 'all' ? `&role_id=${p.role == 'seniors' ? 6 : 2}` : ''}`,
    LIST_STATISTICS_TASKS: (p: TaskStatisticPagination) => `${prefixer}statistics/aufgabe?page=${p.page || 1}&per_page=${p.per_page || 9}&keyword=${p.keyword || ''}&order=desc${p.status !== 'all' ? `&status=${p.status}` : ''}`,
    UPDATE_PROFILE: `${prefixer}users/update`,
    ADD_USER: `${prefixer}administration/users`,
    UPDATE_USER: `${prefixer}administration/users`,
    DOWNLOAD_USER_CSV: (public_id: string | number) => `${prefixer}administration/users/csv/${public_id}`,
    SEND_MAIL_USER: (public_id: string | number) => `${prefixer}administration/users/mail/${public_id}`,
    CHANGE_ROLE: (user_id: number) => `${prefixer}user/${user_id}`,
    RESET_PASSWORD: (user_id: number) => `${prefixer}user/reset/${user_id}`,
    LIST_USERS: (range: Pagination) =>
        `${prefixer}administration/users?page=${range?.page ?? 1}&per_page=${
            range?.per_page ?? 18
        }&keyword=${range.keyword ?? ""}${
            range.role_id != null ? "&role_id=" + range.role_id : ""
        }`,
    DELETE_USER: (public_id: string) =>
        `${prefixer}administration/users/${public_id}`,
    GET_USER_DETAIL: (public_id: string) => `${prefixer}administration/users/${public_id}`,
    GET_RECENT_USER: `${prefixer}statistics/recent-user`,
    GET_USER_REVIEW: (user_public_id: string, page: number = 1) => `${prefixer}administration/user/reviews?page=${page}&per_page=5&user_public_id=${user_public_id}`,
    GET_USER_STAT: (user_public_id: string) => `${prefixer}administration/user/individual-stat?user_public_id=${user_public_id}`,

    // USer request service
    LIST_USER_REQUEST: (range: Pagination) =>
        `${prefixer}administration/user/request?page=${range?.page ?? 1}&per_page=${
            range?.per_page ?? 18
        }&keyword=${range.keyword ?? ""}${
            range.user_public_id ? "&user_public_id=" + range.user_public_id : ""
        }${
            range.status ? "&status=" + range.status : ""
        }`,
    // USer reviews service
    LIST_USER_REVIEWS: (range: Pagination) =>
        `${prefixer}administration/user/reviews?page=${range?.page ?? 1}&per_page=${
            range?.per_page ?? 18
        }&keyword=${range.keyword ?? ""}${
            range.user_public_id ? "&user_public_id=" + range.user_public_id : ""
        }`,
    UPDATE_MY_PASSWORD: `${prefixer}users/me/password`,
    UPDATE_MY_AVATAR: `${prefixer}users/avatar`,
    UPDATE_MY_INFOS: `${prefixer}users/me`,
};

export const RequestUrls = {
    GET_REQUEST_DETAIL_USER: (uuid: string, public_id: string)=> `${prefixer}administration/service/request/${uuid}/${public_id}`,
    GET_REQUEST_DETAIL: (uuid: string)=> `${prefixer}administration/request/${uuid}`,
    GET_REQUEST_LIST: (range: Pagination)=>
    `${prefixer}administration/requests?page=${range?.page ?? 1}&per_page=${
        range?.per_page ?? 20
    }&keyword=${range.keyword ?? ""}${
        range.service_id ? "&service_id=" + range.service_id : ""
    }${
        range.service_finished ? "&service_finished=" + range.service_finished?`true`:`false` : ""
    }${
        range.status ? "&status=" + range.status : ""
    }${
        range.start_date ? "&start_date=" + range.start_date : ""
    }${
        range.end_date ? "&end_date=" + range.end_date : ""
    }${
        range.order ? "&order=" + range.order : ""
    }${
        range.order_field ? "&order_field=" + range.order_field : ""
    }`,
    EXPORT_REQUEST:  (start_date: string, end_date: string, file_type: string)=> 
        `${prefixer}administration/export/services/request?start_date=${
            start_date
        }&end_date=${
            end_date
        }&file_type=${
            file_type
        }`,
    TIME_LINE_INFO: (request_uuid: string)=>`${prefixer}services/request/${request_uuid}/trackings`
}

export const ServiceUrls = {
    ADD_SERVICE: `${prefixer}administration/services`,
    UPDATE_SERVICE: `${prefixer}administration/services`,
    DELETE_SERVICE: (id: number) => `${prefixer}administration/services/${id}`,
    GET_SERVICE_DETAIL: (uuid: string) => `${prefixer}administration/services/${uuid}`,
    GET_SERVICE_LIST: (range: Pagination) =>
        `${prefixer}administration/services?page=${range?.page ?? 1}&per_page=${
            range?.per_page ?? 20
        }&keyword=${range.keyword ?? ""}${
            range.order ? "&order=" + range.order : ""
        }${
            range.order_field ? "&order_field=" + range.order_field : ""
        }`,
    GET_SERVICE_CATEGORY_LIST: (range: Pagination) =>
        `${prefixer}administration/services/categories?page=${range?.page ?? 1}&per_page=${
            range?.per_page ?? 20
        }&keyword=${range.keyword ?? ""}${
            range.order ? "&order=" + range.order : ""
        }${
            range.order_field ? "&order_field=" + range.order_field : ""
        }`,
}

export const StatisticsUrl = {
    LIST_MOST_HOUR: `${prefixer}statistics/most_hours_services`,
    LIST_MOST_SERVICE_BY_MONTH: `${prefixer}statistics/services/turnover`,
    LIST_AMOUNT_SPEND_BY_SENIOR: `${prefixer}statistics/month/amount-spent`,
    GET_KPI_STATS: `${prefixer}statistics/kpi/v2`,
    GET_BAFIN_STATS: `${prefixer}statistics/reporting/bafin`,
    FETCH_LOCALISATION: `${prefixer}statistics/users_by_city`,
    EXPORT_BAFIN_DATA: `${prefixer}users/transaction/export-data`,
    LIST_MONTH_SUMMARY: `${prefixer}statistics/month`,
    LIST_REQUEST_MONTH_SUMMARY: `${prefixer}statistics/month/requests`,
    LIST_REQUEST_USER_WITH_COORDINATES:`${prefixer}statistics/users`,    
    
    GET_USER_REQUEST_STATS: (type: StatsUserType ,year: string, user_public_id: string)=> `${prefixer}statistics/${type}?user_public_id=${user_public_id}&filter=${year}`,

    // GET_USER_REQUEST_SENT: (year: number, user_public_id: string)=> `${prefixer}statistics/requests-sent?user_public_id=${user_public_id}&year=${year}`,
    // GET_USER_CREATED_SERVICE: (year: number, user_public_id: string)=>`${prefixer}statistics/created-services?user_public_id=${user_public_id}&year=${year}`,
    // GET_USER_TREATED_SERVICE: (year: number, user_public_id: string)=>`${prefixer}statistics/treated-services?user_public_id=${user_public_id}&year=${year}`,
    GET_USER_REQUESTS_PER_MONTH: (year: number, user_public_id: string)=>`${prefixer}statistics/anzahl-der-pflege-pro-monat?user_public_id=${user_public_id}&year=${year}`,
};

export const matchMakerUrls = {
    GET_HELFERS: (request_uuid: string)=> `${prefixer}match_checker/helfers/${request_uuid}`,
    CHECK_MATCH:(payload: { uuid: string, helfer_public_id: string }) =>  `${prefixer}match_checker/helfer?uuid=${payload.uuid}&helfer_public_id=${payload.helfer_public_id}`,
}