// @flow
import * as React from 'react';

const NavigationBenutzer = () => {
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8C9.933 8 11.5 6.433 11.5 4.5C11.5 2.567 9.933 1 8 1C6.067 1 4.5 2.567 4.5 4.5C4.5 6.433 6.067 8 8 8Z" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 17H3C1.9 17 1 16.1 1 15C1 12.79 2.79 11 5 11H11C13.21 11 15 12.79 15 15C15 16.1 14.1 17 13 17Z" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 11V9" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M17.5 4V2" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15 6.5H13" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M22 6.5H20" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M20.6756 9.67953L19.2656 8.26953" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.7303 4.73031L14.3203 3.32031" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M15.7303 8.26953L14.3203 9.67953" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M20.6756 3.32031L19.2656 4.73031" stroke="#6C6965" strokeMiterlimit="10" strokeLinejoin="round"/>
            <path d="M17.5 9C18.8807 9 20 7.8807 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.8807 16.1193 9 17.5 9Z" stroke="#6C6965" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default NavigationBenutzer