import { StatsUserType } from "views/users/logic/user_hooks";
import BaseService from "./BaseService";
import { StatisticsUrl } from "./urls";

class StatisticsService {
  static get_most_hour = () => {
    return BaseService.getRequest(StatisticsUrl.LIST_MOST_HOUR, true);
  };

  static get_most_service_by_month = () => {
    return BaseService.getRequest(
      StatisticsUrl.LIST_MOST_SERVICE_BY_MONTH,
      true
    );
  };

  static get_amount_spend_by_senior = () => {
    return BaseService.getRequest(
      StatisticsUrl.LIST_AMOUNT_SPEND_BY_SENIOR,
      true
    );
  };

  static get_kpi_statistics = () => {
    return BaseService.getRequest(
      StatisticsUrl.GET_KPI_STATS,
      true
    );
  };

  static get_user_by_localisation = () => {
    return BaseService.getRequest(
      StatisticsUrl.FETCH_LOCALISATION,
      true
    );
  };

  static get_user_request_stats = (type: StatsUserType, year: string, user_public_id: string) => {
    return BaseService.getRequest(StatisticsUrl.GET_USER_REQUEST_STATS(type, year, user_public_id), true)
  }

  // static get_user_request_sent = (year: number, user_public_id: string)=>{
  //   return BaseService.getRequest(StatisticsUrl.GET_USER_REQUEST_SENT(year, user_public_id), true)
  // }

  // static get_user_created_service = (year: number, user_public_id: string)=>{
  //   return BaseService.getRequest(StatisticsUrl.GET_USER_CREATED_SERVICE(year, user_public_id), true)
  // }

  // static get_user_treated_service = (year: number, user_public_id: string)=>{
  //   return BaseService.getRequest(StatisticsUrl.GET_USER_TREATED_SERVICE(year, user_public_id), true)
  // }

  static get_user_requests_per_month = (year: number, user_public_id: string) => {
    return BaseService.getRequest(StatisticsUrl.GET_USER_REQUESTS_PER_MONTH(year, user_public_id), true)
  }

}

export default StatisticsService;
