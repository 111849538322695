import React, { useEffect } from 'react';
import socketio from "socket.io-client";
import config from "config";
import { UserContext, UserContextType } from "views/auth/context/UserContext";

interface SocketContextProviderProps {
    children: React.ReactNode
}

const socket = socketio(config.socket_url, { transports: ['websocket'] });


export const SocketContext = React.createContext(socket);

export const SocketContextProvider = ({ children }: SocketContextProviderProps) => {

    const { auth } = React.useContext(UserContext) as UserContextType
    useEffect(() => {
        socket.connect();
        socket.on('connect', () => {
            console.log("socket connected")
        });

        socket.on('connect_error', (err: any) => {
            console.log("socket error: ", err)
        });

        socket.emit('EVENT_FIRST_CONNECT', auth?.token ?? "");
        socket.emit('EVENT_JOIN_CHANNEL', `${auth?.user?.public_id ?? ""}`);

        socket.on('disconnect', () => {
            console.log("Disconnect from socket")
        });
        //@ts-ignore
        window.socket = socket;
    }, [])

    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>

}