import * as React from 'react';
import { useState } from 'react';
import styles from "../Application.module.css";
import navigation_config, { Menu } from "../navigation_config";
import { Box, Button, ButtonBase, Drawer, Stack, Tooltip } from "@mui/material";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import useResponsive from "hooks/useResponsive";
import { UserContext, UserContextType } from "views/auth/context/UserContext";
import LogoutIcon from 'assets/logout';
import { AddOrUpdateUser } from "components";
import clsx from 'clsx';


interface NavbarProps {
    open: boolean
    onClose: () => void
    isCollapsed?: boolean
}

const Navbar = (props: NavbarProps) => {
    const history = useHistory();
    const isDesktop = useResponsive('up', 'lg');
    const isMobile = useResponsive('down', 'sm');
    const [addUser, setAddUser] = useState(false);
    const { logout } = React.useContext(UserContext) as UserContextType
    const { t } = useTranslation();
    const handleLogout = async () => {
        logout();
        history.push("/");
    };

    const collapsed = (props.isCollapsed && !props.open);

    const content = <Box
        className={collapsed ? styles.navbarCollapsed : styles.navbar}
        sx={{
            width: {
                xs: "100vw",
                sm: "calc(100vw * 0.4)",
                lg: "unset"
            },
            backgroundColor: {
                xs: "#181835",
                md: "#fff"
            },
            pl: {
                xs: '30px',
                sm: '30px',
            },
            pr: {
                xs: '16px',
                sm: '30px',
            },
            pt: {
                xs: '38px',
                sm: 0
            },

        }}
    >
        {
            addUser &&
            <AddOrUpdateUser
                open={addUser}
                onClose={() => setAddUser(false)}
            />
        }
        <Stack
            direction={"row"}
            justifyContent={isMobile ? "space-between" : "center"}
            alignItems={"center"}
            sx={{
                px: isMobile ? 0 : 2,
                py: 1,
                mb: 3,
                pt: isDesktop ? 3 : 1,
            }}
        >
            <img height={64} src={isMobile ? "/logo_horizontal.svg" : "/logo.svg"} alt="" />

            {
                isMobile ? (
                    <Button
                        color={"primary"}
                        startIcon={<img src="/icons/add.svg" alt="" />}
                        onClick={() => {
                            setAddUser(true)
                        }}
                    >
                        {t('btn_label_add_user')}
                    </Button>
                ) : props.open ? (
                    <Box
                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 24
                        }}
                    >
                        <img src="/icons/Close.svg" width={32} alt="" />
                    </Box>): null
                
            }
        </Stack>

        {
            navigation_config.map(menu => {

                return <MenuItem
                    key={menu.title}
                    onClick={() => {
                        history.push(menu.href)
                        props.onClose();
                    }}
                    menu={menu}
                    navCollapsed={collapsed}
                />
            })
        }
        <Box sx={{
            bgcolor: '#CEE0E1',
            height: '1px',
            mb: 4,
            mt: 2,
        }} />
        <MenuItem
            onClick={handleLogout}
            menu={{
                title: 'navigation_config_7',
                href: '#',
                icon: LogoutIcon
            }}
            navCollapsed={collapsed}
        />
    </Box>;

    return (
        isDesktop ? content : <Drawer
            sx={{
                position: 'relative',
                "& .MuiPaper-root": {
                    borderRadius: "0 !important"
                }

            }}
            open={props.open}
            onClose={props.onClose}

        >
            {content}

        </Drawer>
    );
};


type MenuItemProps = {
    menu: Menu
    onClick: () => void;
    navCollapsed?: boolean;
};

export const MenuItem = (props: MenuItemProps) => {
    const { menu } = props;
    const history = useHistory();
    const { t } = useTranslation();
    const isMobile = useResponsive('down', 'sm')

    const active = history.location.pathname.includes(menu.href);
    const Icon = menu.icon;
    return (<Tooltip title={t(menu.title)}
        placement='right'
        PopperProps={{ sx: { display: !!props.navCollapsed ? 'initial' : 'none' } }}
        disableFocusListener={!!!props.navCollapsed}
        disableHoverListener={!!!props.navCollapsed}
    // disableTouchListener={!!!props.navCollapsed}
    // disableInteractive={!!!props.navCollapsed}
    >
        <ButtonBase
            key={menu.title}
            onClick={props.onClick}
            sx={{
                py: 2,
                fontWeight: active ? 600 : 400,
                fontSize: '16px',
                fontFamily: 'Gantari',
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'left',
                width: '100%',
                borderRadius: '5px',
                px: 2,
                backgroundColor: props.navCollapsed
                    ? active ? '#E4E5F7' : 'transparent'
                    : active ? 'primary.main' : 'transparent',
                color: props.navCollapsed
                    ? active ? 'primary.main' : (isMobile ? "#FFFFFF" : '#6C6965')
                    : active ? 'text.white' : (isMobile ? "#FFFFFF" : '#6C6965'),
                transitionDuration: '350ms',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '&:hover': {
                    color: props.navCollapsed
                        ? '#282B89'
                        : active ? '#FFFFFF' : '#282B89',
                    '& path': {
                        stroke: props.navCollapsed
                            ? '#282B89'
                            : active ? '#FFFFFF' : '#282B89',
                    },
                },
                '& path': {
                    stroke: active
                        ? props.navCollapsed
                            ? '#282B89'
                            : '#FFFFFF'
                        : (isMobile
                            ? "#FFFFFF"
                            : '#6C6965'
                        ),
                },
                'svg': {
                    width: '24px',
                    mr: props.navCollapsed ? 0 : 2,
                }
            }}>
            <Icon />
            {!props.navCollapsed ? t(menu.title) : null}
        </ButtonBase>
    </Tooltip>

    );
};

export default Navbar